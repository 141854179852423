@import './variables';
@import '~bootstrap/scss/bootstrap.scss';

html {
  height: 100%;
  min-height: 100%;
  // overflow: hidden;
}

body {
  background-image: url('../images/app-bg.jpg');
  background-size: cover;
  background-attachment: fixed;
}

#enhesa-logo {
  background-image: url('../images/logo.png');
  background-size: cover;
  background-size: 163px 94px;
  margin-top: -90px;
  width: 163px;
  height: 94px;
  position: fixed;
  z-index: 1;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: lighten($primary, 63%);
}

.w-20 {
  width: 20% !important;
}

.mw-30 {
  min-width: 20% !important;
}

.btn:disabled {
  cursor: auto;
}

.thumb-vertical {
  border-radius: 3px;
  background-color: rgba(lighten($primary, 20%), 0.5);
}
