.no-highlight {
  .entity-strong {
    display: none;
  }
  mark {
    line-height: inherit;
    background-color: transparent;
    padding: 0 !important;
    margin: 0 !important;
    .selected {
      outline: 5px solid #ffff66;
      outline-offset: -3px;
    }
  }
}
