//
// Color system
//

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #ecf0f1;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #b4bcc2;
$gray-600: #95a5a6;
$gray-700: #7b8a8b;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #294a8d;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #c24d40;
$orange: #c25e40;
$yellow: #f39c12;
$green: #25a087;
$teal: #20c997;
$cyan: #2570a1;

$primary: $blue;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-700;

$yiq-contrasted-threshold: 175;

// Links

$link-color: $success;

// Fonts

$font-size-base: 0.975rem;
$font-weight-base: 300;
$font-weight-bolder: 700;

$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem;

// Forms

$custom-control-gutter: 0.45rem;

$custom-control-indicator-size: 1.5rem;
$custom-control-indicator-bg: $white;
$custom-checkbox-indicator-border-radius: 5px;

$custom-control-indicator-disabled-bg: $gray-100;
$custom-select-box-shadow: none;

// Tables

$table-accent-bg: $gray-200;

// Progress bars

$progress-height: 1.5rem;

// List group

$list-group-hover-bg: $gray-200;
$list-group-disabled-bg: $gray-200;

// Close

$close-color: $gray-800;
$close-text-shadow: none;
