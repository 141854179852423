@mixin width {
  width: auto;
}

table {
  th:focus {
    outline: none;
  }
  &.table-fit {
    @include width;
    table-layout: auto;
    thead th,
    tfoot th {
      @include width;
    }
    tbody td,
    tfoot td {
      @include width;
    }
  }
}
